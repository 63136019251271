import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CreateWatchlistModal from "../components/watchlist/modal/CreateWatchlistModal";
import RenameWatchlistModal from "../components/watchlist/modal/RenameWatchlistModal";
import ConfirmationModal from "../components/modal/ConfirmationModal";

import {
	Error,
} from '../components';

import {
	WatchlistTable,
} from '../components/watchlist';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrash,
	faEdit,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';

import { Helmet } from "react-helmet";

import ApiHelper from '../api/apiHelper';
const apiHelper = new ApiHelper();

const WatchlistTab = ({ watchlist, currentWatchlistTab, getWatchlistList }) => {
	const [ showRenameWatchlistModal, setShowRenameWatchlistModal ] = useState(false);
	const [ showConfirmDeleteModal, setShowConfirmDeleteModal ] = useState(false);

	const toggleRenameWatchlistModal = () => { setShowRenameWatchlistModal(!showRenameWatchlistModal) };
	const toggleConfirmDeleteModal = () => { setShowConfirmDeleteModal(!showConfirmDeleteModal) };

	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();
	const { t } = useTranslation();

	const deleteWatchlist = async () => {
		try {
			var deleteWatchlistResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + watchlist.watchlistId + "/delete"
			);

			if (deleteWatchlistResponse.status === "success") {
				toggleConfirmDeleteModal();
				getWatchlistList();
			} else {
				console.log(deleteWatchlistResponse);
			}
		} catch (e) {
			console.log(e.message);
		}
	}

	return (
		<>
			<RenameWatchlistModal show={ showRenameWatchlistModal } toggle={ toggleRenameWatchlistModal } watchlist={ watchlist } getWatchlistList={ getWatchlistList } />
			<ConfirmationModal show={ showConfirmDeleteModal } toggle={ toggleConfirmDeleteModal } onConfirm={ deleteWatchlist } confirmText={ t("watchlist.confirm-delete-watchlist") } yesText={ t("watchlist.action.delete") } danger />
			<Nav.Item>
				<Dropdown as={ ButtonGroup } className="position-static">
					<Button variant={ "watchlist-" + watchlist.watchlistId === currentWatchlistTab ? "light" : null } className={ "watchlist-" + watchlist.watchlistId !== currentWatchlistTab ? "text-primary" : null } >
						<Nav.Link eventKey={ "watchlist-" + watchlist.watchlistId } className="p-0">
							{ watchlist.watchlistName }
						</Nav.Link>
					</Button>
					<Dropdown.Toggle split variant={ "watchlist-" + watchlist.watchlistId === currentWatchlistTab ? "light" : null } />
					<Dropdown.Menu>
						<Dropdown.Item onClick={ toggleRenameWatchlistModal } ><FontAwesomeIcon icon={ faEdit } className="me-2" />{ t("watchlist.action.rename-watchlist") }</Dropdown.Item>
						<Dropdown.Item onClick={ toggleConfirmDeleteModal } className="text-danger"><FontAwesomeIcon icon={ faTrash } className="me-2" />{ t("watchlist.action.delete-watchlist") }</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Nav.Item>
		</>
	)
};

const Watchlist = () => {
	// General Page States
	const [ loading, setLoading ] = useState(false);
	const [ forbidden, setForbidden ] = useState(false);
	const [ error, setError ] = useState(false);

	// Data
	const [ canBypassWatchlistLimit, setCanBypassWatchlistLimit ] = useState(false);
	const [ watchlistList, setWatchlistList ] = useState([ ]);
	const [ currentWatchlistTab, setCurrentWatchlistTab ] = useState(null);
	const [ stockList, setStockList ] = useState([ ]);

	const [ showCreateWatchlistModal, setShowCreateWatchlistModal ] = useState(false);

	const { t } = useTranslation();
	const { getAccessTokenSilently } = useAuth0();
	const location = useLocation();

	const toggleCreateWatchlistModal = () => { setShowCreateWatchlistModal(!showCreateWatchlistModal) };

	const getCanBypassWatchlistLimit = async () => {
		setLoading(true);

		try {
			var result = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/bypass-limit",
				"GET"
			);

			setCanBypassWatchlistLimit(result.status === "success");
		} catch (e) {
			console.log(e.message);
		}
	};

	const getWatchlistList = async () => {
		setLoading(true);

		try {
			var userWatchlist = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/user",
				"GET"
			);

			setWatchlistList([ ...userWatchlist.data ]);

			if (userWatchlist.data.length > 0) {
				setCurrentWatchlistTab("watchlist-" + userWatchlist.data[0].watchlistId);
			}

			setLoading(false);
		} catch (e) {
			console.log(e.message);
		}
	};

	const getAvailableStockList = async () => {
		try {
			var stockListResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/stocks",
				"GET"
			);

			if (stockListResponse.status === "Forbidden") {
				setForbidden(true);
			} else {
				setForbidden(false);
				setStockList(stockListResponse.data);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	useEffect(() => {
		getCanBypassWatchlistLimit();
		getAvailableStockList();
		getWatchlistList();
	}, [ ]);

	if (forbidden) {
		return (
			<div className="mt-5">
				<Error />
			</div>
		);
	}

	return (
		<>
			<CreateWatchlistModal show={ showCreateWatchlistModal } toggle={ toggleCreateWatchlistModal } getWatchlistList={ getWatchlistList } />
			<Helmet>
				<title>Watchlist - Finetic App</title>
			</Helmet>
			
			<div className="row h-100">
				<div className="col-12 px-0 overflow-x-hidden">
					<Tab.Container activeKey={ currentWatchlistTab } onSelect={ setCurrentWatchlistTab } mountOnEnter>
						<Nav className="mb-3 watchlist-tabs">
							{
								watchlistList.map((watchlist) => (
									<WatchlistTab watchlist={ watchlist } currentWatchlistTab={ currentWatchlistTab } getWatchlistList={ getWatchlistList } />
								))
							}
							{
								!loading && (canBypassWatchlistLimit || watchlistList.length < 3) ?
								<div className="pt-1 ms-2 nav-item">
										<Button variant="primary" size="sm" className={ watchlistList.length > 0 ? "shadow-sm" : "" } onClick={ toggleCreateWatchlistModal } ><FontAwesomeIcon icon={ faPlus } className="me-2" />{ t("watchlist.action.create-watchlist") }</Button>
									</div> : null
							}
						</Nav>

						<div className="row px-3">
							<Tab.Content>
							{
								watchlistList.map((watchlist) => (
									<Tab.Pane eventKey={ "watchlist-" + watchlist.watchlistId } >
										<WatchlistTable watchlistId={ watchlist.watchlistId } stockList={ stockList } />
									</Tab.Pane>
								))
							}
							</Tab.Content>
						</div>
					</Tab.Container>
					{
						!loading && watchlistList.length === 0 ?
							<p className="px-3 text-muted">{ t("watchlist.no-watchlist") }</p> : null
					}
				</div>
			</div>

			{ error ? <Error /> : null }
		</>
	);
};

export default Watchlist;
