import { useRef, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import WatchlistData from './WatchlistData';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSortUp,
	faSortDown,
	faSort,
	faArrowUp,
	faArrowDown,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import ApiHelper from '../../api/apiHelper';
import WatchlistActionModal from "./modal/WatchlistActionModal";
const apiHelper = new ApiHelper();

const WatchlistTable = ({ watchlistId, stockList }) => {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(false);

	const [ canBypassWatchlistLimit, setCanBypassWatchlistLimit ] = useState(false);
	const [ watchlist, setWatchlist ] = useState([ ]);
	const [ rawWatchlist, setRawWatchlist ] = useState([ ]);

	const [ sortKey, setSortKey ] = useState("stockCode");
	const [ sortAscending, setSortAscending ] = useState(true);

	const [ showAddToWatchlistModal, setShowAddToWatchlistModal ] = useState(false);

	const location = useLocation();
	const { getAccessTokenSilently } = useAuth0();
	const { t, i18n } = useTranslation();

	const toggleAddToWatchlistModal = () => { setShowAddToWatchlistModal(!showAddToWatchlistModal) };

	const getCanBypassWatchlistLimit = async () => {
		setLoading(true);

		try {
			var result = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/bypass-limit",
				"GET"
			);

			setCanBypassWatchlistLimit(result.status === "success");
		} catch (e) {
			console.log(e.message);
		}
	};

	const getWatchlist = async () => {
		setLoading(true);

		try {
			var watchlistDataResponse = await apiHelper.request(
				getAccessTokenSilently,
				location,
				"watchlist/" + watchlistId,
				"GET"
			);

			setWatchlist(watchlistDataResponse.data.stockList);
			setRawWatchlist(watchlistDataResponse.data.stockList);
			setLoading(false);
		} catch (e) {
			setError(true);
			setLoading(false);
		}
	};

	const processList = () => {
		var newList = [ ...rawWatchlist ];

		// Sort
		if (sortKey === "stockCode") {
			var numberStockCode = newList.filter(a => !isNaN(a.stockCode)).sort((a, b) => Number(a.stockCode) > Number(b.stockCode) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1));
			var charStockCode =  newList.filter(a => isNaN(a.stockCode)).sort((a, b) => (a.stockCode > b.stockCode) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1));

			newList = sortAscending ? [ ...numberStockCode, ...charStockCode ] : [ ...charStockCode, ...numberStockCode ];
		} else {
			newList = newList.sort((a, b) => {
				switch (sortKey) {
					case "cost":
						return (a.buyPrice * a.shares > b.buyPrice * b.shares) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1);
					case "total-value":
						return (a.currentPrice * a.shares > b.currentPrice * b.shares) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1);
					case "profit":
						var profitA = (a.currentPrice * a.shares) - (a.buyPrice * a.shares);
						var profitB = (b.currentPrice * b.shares) - (b.buyPrice * b.shares);
						return (profitA > profitB) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1);
					case "profitChange":
						var profitChangeA = (((a.currentPrice * a.shares) - (a.buyPrice * a.shares)) / (a.buyPrice * a.shares)) * 100;
						var profitChangeB = (((b.currentPrice * b.shares) - (b.buyPrice * b.shares)) / (b.buyPrice * b.shares)) * 100;
						return (profitChangeA > profitChangeB) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1);
					default:
						return (a[sortKey] > b[sortKey]) ? (sortAscending ? 1 : -1) : (sortAscending ? -1 : 1);
				}
			});
		}

		setWatchlist(newList);
	};

	const sortData = (newSortKey, doSort = true) => {
		var sameKey = sortKey === newSortKey;
		var isAscending = sortAscending;

		if (doSort && sameKey) {
			isAscending = !sortAscending;
		}

		setSortKey(newSortKey);
		setSortAscending(isAscending);
	}

	useEffect(() => {
		getCanBypassWatchlistLimit();
		getWatchlist();
	}, [ ]);

	useEffect(() => {
		getWatchlist();
	}, [ watchlistId ]);

	useEffect(() => {
		processList();
	}, [ rawWatchlist, sortKey, sortAscending ]);

	if (loading) {
		return (
			<div className="row">
				<div className="col-12 col-xxl-10">
					<SkeletonTheme color="rgba(0, 0, 0, 0.05)" highlightColor="rgba(0, 0, 0, 0.01)">
						<Skeleton count={ 10 } height="3em" className="my-1" />
					</SkeletonTheme>
				</div>
			</div>
		);
	}

	return (
		<>
			<WatchlistActionModal show={ showAddToWatchlistModal } toggle={ toggleAddToWatchlistModal } watchlistId={ watchlistId } stockList={ stockList } getWatchlist={ getWatchlist } />
			<div className="row">
				<div className="col-12 col-lg-3 mb-3">
					<div className="d-grid gap-2">
						<Button variant="primary" className="text-nowrap" onClick={ toggleAddToWatchlistModal } disabled={rawWatchlist.length === 20 && !canBypassWatchlistLimit} >
							<FontAwesomeIcon icon={ faPlus } className="me-2" />{ t("watchlist.action.add-to-watchlist") }
						</Button>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-8 d-lg-none">
					<select className="form-select" id="watchlist-sort-by" onChange={ (e) => { setSortKey(e.target.value) }} >
						<option value="" disabled>{ t("watchlist.sort.sorting") }</option>
						<option value="stockCode">{ t("watchlist.sort.sort-by") } { t("watchlist.data.ticker") }</option>
						<option value="currentPrice">{ t("watchlist.sort.sort-by") } { t("watchlist.data.price") }</option>
						<option value="currentVolume">{ t("watchlist.sort.sort-by") } { t("watchlist.data.volume") }</option>
						<option value="buyPrice">{ t("watchlist.sort.sort-by") } { t("watchlist.data.buy-price") }</option>
						<option value="shares">{ t("watchlist.sort.sort-by") } { t("watchlist.data.shares") }</option>
						<option value="total-value">{ t("watchlist.sort.sort-by") } { t("watchlist.data.total-value") }</option>
						<option value="profit">{ t("watchlist.sort.sort-by") } { t("watchlist.data.profit") }</option>
						<option value="profitChange">{ t("watchlist.sort.sort-by") } { t("watchlist.data.profit") + " " + t("watchlist.data.change-abbr") }</option>
					</select>
				</div>

				<div className="col-4 d-flex d-lg-none align-items-center justify-content-center sort-direction">
					<label htmlFor="sort-direction" className={ "form-check-label me-2 sort-arrow-up" + (sortAscending ? " sort-active" : "") }>
						<FontAwesomeIcon icon={ faArrowUp } />
					</label>
					<div className="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="sort-direction" onChange={ (e) => { setSortAscending(!sortAscending)} } />
						<label htmlFor="sort-direction" className={ "form-check-label" + (!sortAscending ? " sort-active" : "") } >
							<FontAwesomeIcon icon={ faArrowDown } />
						</label>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-xxl-10">
					<div className="d-none d-lg-block">
						{
							watchlist.length > 0 ?
								<Table responsive className="data-table watchlist-table">
									<thead>
										<tr>
											<th className="sortable" onClick={ () => { sortData("stockCode") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2">{ t("watchlist.data.ticker") }</span>
													{ sortKey === "stockCode" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right sortable" onClick={ () => { sortData("currentPrice") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2">{ t("watchlist.data.price") }</span>
													{ sortKey === "currentPrice" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right sortable" onClick={ () => { sortData("currentVolume") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2 d-lg-none d-xxl-block">{ t("watchlist.data.volume") }</span>
													<span className="me-2 d-xxl-none">{ t("watchlist.data.volume-abbr") }</span>
													{ sortKey === "currentVolume" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right sortable" onClick={ () => { sortData("buyPrice") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2">{ t("watchlist.data.buy-price") }</span>
													{ sortKey === "buyPrice" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right sortable" onClick={ () => { sortData("shares") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2">{ t("watchlist.data.shares") }</span>
													{ sortKey === "shares" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right sortable" onClick={ () => { sortData("cost") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2">{ t("watchlist.data.cost") }</span>
													{ sortKey === "cost" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right sortable" onClick={ () => { sortData("total-value") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2 d-lg-none d-xxl-block">{ t("watchlist.data.total-value") }</span>
														<span className="me-2 d-xxl-none">{ t("watchlist.data.total-value") }</span>
													{ sortKey === "total-value" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right sortable" onClick={ () => { sortData("profit") }} >
												<div className="d-flex align-items-center justify-content-between">
													<span className="me-2">{ t("watchlist.data.profit") }</span>
													{ sortKey === "profit" ? (sortAscending ? <FontAwesomeIcon icon={ faSortUp } className="active" /> : <FontAwesomeIcon icon={ faSortDown } className="active" />) : <FontAwesomeIcon icon={ faSort } /> }
												</div>
											</th>
											<th className="text-right">
											</th>
										</tr>
									</thead>
									<tbody>
										{
											watchlist.map(stock => (
												<WatchlistData key={ "stock-" + stock.watchlistStockId } stock={ stock } watchlistId={ watchlistId } getWatchlist={ getWatchlist } />
											))
										}
									</tbody>
								</Table> : null
						}
					</div>

					<div className="d-lg-none">
						{
							watchlist.map(stock => (
								<WatchlistData key={ "stock-mobile-" + stock.watchlistStockId + "-sm" } watchlistId={ watchlistId } stock={ stock } getWatchlist={ getWatchlist } mobile />
							))
						}
					</div>

					<div className="mt-2 text-muted text-center">
						<p>{ t("watchlist.total-records", { count: rawWatchlist.length }) }</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default WatchlistTable;
